<template>
  <v-dialog v-model="visible" width="750">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="accent"
        depressed
        icon
        small
        @click.stop="selectContact(contactId)"
      >
        <v-icon small>mdi-face-agent</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-row class="pt-3" no-gutters align="center" justify="space-between">
        <v-col cols="auto">
          <v-card-title>
            Supplier Interaction
          </v-card-title>
        </v-col>
        <v-col cols="auto">
          <v-row no-gutters align="center">
            <v-col cols="auto">
              <div class="text-button font-weight-bold px-3">Sending As</div>
            </v-col>
            <v-col cols="auto" class="px-3">
              <v-btn-toggle v-model="company" group :color="companyColor">
                <v-btn
                  value="utility hive"
                  :disabled="!employeeCampaign.utilityHive"
                >
                  Utility Hive
                </v-btn>
                <v-btn
                  value="commercial support service"
                  :disabled="!employeeCampaign.css"
                >
                  Commercial Support Service
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="pa-3" v-if="!employeeCampaign.utilityHive && !employeeCampaign.css">
        <v-alert class="mb-0" type="warning">
          <v-row align="center">
            <v-col class="grow">
              You don't have campaigns assigned.
            </v-col>
          </v-row>
        </v-alert>
      </div>
      <div v-else class="mt-4">
        <v-tabs
          v-model="tab"
          :background-color="companyColor"
          slider-color="primary"
          dark
          grow
        >
          <v-tab  v-for="item in showTabs" :key="item">
            {{  item }}
          </v-tab>
        </v-tabs>


        <v-tabs-items v-model="tab">
          <v-tab-item>
            <InfoRequestForm v-bind="sharedProps" v-on="sharedEvents" />
          </v-tab-item>
          <v-tab-item v-if="company === 'utility hive'">
            <Termination v-bind="sharedProps" v-on="sharedEvents" />

          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  props: {
    contactId: {
      type: String,
      default: ""
    }
  },
  components: {
    InfoRequestForm: () => import("@/components/forms/information-request"),
    Termination: () => import("@/components/forms/termination"),
  },
  data: () => ({
    visible: false,
    tab: 0,
    tabs: ["Information Request","Termination"],
    company: "",
  }),
  computed: {
    ...mapGetters("addresses", [
      "landlord",
      "previousTenant",
      "brewery",
      "loadingLandlordInfo"
    ]),
    ...mapGetters("employee", ["campaignAssigned", "employeeCampaign"]),
    companyColor() {
      if (!this.company) return "grey"
      return this.company === "utility hive" ? "accent" : "green"
    },
    selectedCampaign(){
      return this.company === "utility hive" ? this.employeeCampaign.utilityHive : this.employeeCampaign.css
    },
    showTabs(){
      let tabs = this.tabs
      if(this.company === 'utility hive'){
       tabs = ["Information Request", "Termination"]
         return tabs
      } else {
       tabs = ["Information Request"]
         return tabs

      }
    },
    sharedProps() {
      return {
        companyColor: this.companyColor,
        disabledSubmit: !this.company,
        formName: this.tabs[this.tab],
        selectedCompany: this.company
      }
    },
    sharedEvents() {
      return {
        "click-close": () => {
          this.resetInitialData()
          this.visible = false
        },
      }
    },
  },
  methods: {
    ...mapMutations("contacts", {
      selectContact: "setSelectedContact"
    }),
    ...mapActions("addresses", [
      "fetchLandlordInfo",
      "fetchPreviousTenantInfo",
      "fetchBreweryInfo"
    ]),
    startFetchingData(id) {
      this.fetchLandlordInfo(id)
      this.fetchPreviousTenantInfo(id)
      this.fetchBreweryInfo(id)
    },
    resetInitialData() {
      this.visible = false
      this.tab = 0
      this.company = ""
    },
  }
}
</script>
